import React from 'react';
import styled from '@emotion/styled';
import Select, { StylesConfig, components } from 'react-select';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { GRANNY_SMITH, OUTER_SPACE } from '@constants';
import ChevronBottomIcon from '@assets/icons/ChevronBottomIcon';
import Button from '@components/Button';
import { theme } from '@styles';
import { navigate } from 'gatsby';
import axios from 'axios';

const { breakpoints } = theme;

type Props = {};

type OptionType = {
  label: string;
  value: string;
};

const organizationOptions: OptionType[] = [
  { value: 'consumer-health-fitness', label: 'Consumer health & fitness' },
  { value: 'video-production-studio', label: 'Video production studio' },
  { value: 'corporate-wellness', label: 'Corporate wellness' },
  { value: 'health-provider', label: 'Health provider' },
  { value: 'software-for-trainers', label: 'Software for trainers' },
  { value: 'software-for-gyms', label: 'Software for gyms' },
  { value: 'connected-hardware', label: 'Connected hardware' },
  { value: 'gym-chain', label: 'Gym chain' },
  { value: 'nutrition-platform', label: 'Nutrition platform' },
  { value: 'independent-gym-or-studio', label: 'Independent gym or studio' },
  { value: 'sports-team', label: 'Sports team' },
  { value: 'social-platform-for-creators', label: 'Social platform for creators' },
  { value: 'physio-center', label: 'Physiotherapy center' },
  { value: 'independent-health-fitness-pro', label: 'Independent health & fitness professional' },
  { value: 'other', label: 'Other' },
];

const alreadyVideoContentOptions: OptionType[] = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
  { value: 'Planned', label: 'I am planning to' },
];

const selectStyle: StylesConfig<OptionType> = {
  control: (provided) => ({
    ...provided,
    'border': `1px solid transparent`,
    'backgroundColor': 'white',
    'outline': 'none',
    'boxShadow': 'none',
    'height': '53px',
    'borderRadius': 12,
    '&:focus': {
      border: `1px solid ${GRANNY_SMITH} !important`,
    },
    '&:hover': {
      border: `1px solid ${GRANNY_SMITH} !important`,
    },
    '&:active': {
      border: `1px solid ${GRANNY_SMITH} !important`,
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    marginLeft: '0px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: '22px',
    borderRadius: 12,
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 12,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15);',
  }),
  menuList: (provided) => ({
    ...provided,
    borderRadius: 12,
    boxShadow: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    'color': OUTER_SPACE,
    'backgroundColor': 'white',
    'paddingLeft': '22px',
    'position': 'relative',
    '&:after': {
      content: "''",
      position: 'absolute',
      left: 0,
      height: '100%',
      width: '0.5px',
      background: OUTER_SPACE,
      display: state.isSelected ? 'initial' : 'none',
    },
    ':active': {
      backgroundColor: 'white',
    },
    ':hover': {
      opacity: 0.75,
    },
  }),
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronBottomIcon strokeWidth={2} stroke={OUTER_SPACE} />
    </components.DropdownIndicator>
  );
};

const BookForm: React.FC<Props> = () => {
  const { register, handleSubmit, control } = useForm();

  const onSubmit: SubmitHandler<{
    fullName: string;
    businessEmail: string;
    businessWebsite: string;
    organization: { value: string; label: string };
    videoContent: { value: string; label: string };
  }> = async (data: {
    fullName: string;
    businessEmail: string;
    businessWebsite: string;
    organization: { value: string; label: string };
    videoContent: { value: string; label: string };
  }) => {
    try {
      await axios.post(`${process.env.GATSBY_API_URL}/demo/booking`, {
        name: data.fullName,
        email: data.businessEmail,
        website: data.businessWebsite,
        organizationType: data.organization.label,
        hasVideoContentOffering: data.videoContent.value,
      });
      navigate('/book-success');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper>
          <Label htmlFor='fullName'>Full name*</Label>
          <Input type='text' id='fullName' {...register('fullName', { required: true })} placeholder={'Full name...'} />
        </InputWrapper>

        <InputWrapper>
          <Label htmlFor='businessEmail'>Business email*</Label>
          <Input
            type='email'
            id='businessEmail'
            {...register('businessEmail', { required: true })}
            placeholder={'Email address...'}
          />
        </InputWrapper>

        <InputWrapper>
          <Label htmlFor='businessWebsite'>Business website*</Label>
          <Input
            type='text'
            id='businessWebsite'
            {...register('businessWebsite', { required: true })}
            placeholder={'Team website link...'}
          />
        </InputWrapper>

        <InputWrapper>
          <Label htmlFor='organization'>Your organization*</Label>
          <Controller
            control={control}
            name='organization'
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomSelect
                id='organization'
                styles={selectStyle}
                placeholder={'Choose an answer'}
                options={organizationOptions}
                onChange={onChange}
                ref={ref}
                value={value}
                onBlur={onBlur}
                components={{
                  DropdownIndicator: DropdownIndicator,
                  IndicatorSeparator: null,
                }}
              />
            )}
          />
        </InputWrapper>

        <InputWrapper>
          <Label htmlFor='videoContent'>Already offering video content to your clients?*</Label>
          <Controller
            control={control}
            name='videoContent'
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomSelect
                id='videoContent'
                styles={selectStyle}
                placeholder={'Choose an answer'}
                options={alreadyVideoContentOptions}
                onChange={onChange}
                ref={ref}
                value={value}
                onBlur={onBlur}
                components={{
                  DropdownIndicator: DropdownIndicator,
                  IndicatorSeparator: null,
                }}
              />
            )}
          />
        </InputWrapper>

        <ButtonWrapper>
          <Button elementType='submit' label='BOOK YOUR DEMO' height={69} fontSize={16} />
        </ButtonWrapper>
      </Form>
    </Wrapper>
  );
};

export default BookForm;

const ButtonWrapper = styled.div`
  margin-top: 24px;
`;

const Label = styled.label`
  font-weight: 700;
  margin-bottom: 16px;
`;

const Wrapper = styled.div`
  background: #f0f5f5;
  border-radius: 40px;
  padding: 32px 24px;
  max-width: 666px;
  margin: 0 24px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    padding: 48px;
    margin: 0 auto;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  height: 53px;
  font-size: 18px;
  line-height: 27px;
  border: 1px solid transparent;
  border-radius: 12px;
  padding: 22px;
  &:focus,
  &:active,
  &:hover {
    outline: none;
    border: 1px solid ${GRANNY_SMITH};
  }
`;

const CustomSelect = styled(Select)``;
